.backdrop.thumbnail__backdrop {
  overflow-x: hidden;
}

.backdrop.thumbnail__backdrop.isHidden {
  display: none;
}

.thumbnail__box {
  position: relative;
}

.thumbnail__box:before {
  content: "";
  background-image: url("https://crazzzyhall.com/images/icons/kangaroo2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 320px;
  height: 200px;
  display: block;
  position: absolute;
}

@media screen and (width <= 767px) {
  .thumbnail__box {
    padding-bottom: 240px;
  }

  .thumbnail__box:before {
    bottom: 0;
  }
}

@media screen and (width >= 768px) {
  .thumbnail__box:before {
    width: 640px;
    height: 440px;
    top: 160px;
    left: 350px;
  }
}

@media screen and (width >= 1440px) {
  .thumbnail__box {
    padding-right: 800px;
  }

  .thumbnail__box:before {
    top: 60px;
    left: initial;
    width: 800px;
    height: 560px;
    right: 0;
  }
}

.thumbnail__subtitle {
  color: #fff;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  display: block;
}

.thumbnail__subtitle--accent {
  color: #9cfe01;
  margin-top: 12px;
  font-weight: 600;
}

.thumbnail__strong {
  color: #fff;
  text-transform: uppercase;
  border: 4px solid #9cfe01;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: fit-content;
  margin: 64px 0;
  padding: 24px 40px;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  position: relative;
}

.thumbnail__strong:before {
  content: "";
  background-color: #9cfe01;
  border-radius: 50%;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
}

@media screen and (width <= 767px) {
  .thumbnail__strong {
    font-size: 20px;
    line-height: 20px;
  }
}

.thumbnail__text, .thumbnail__link {
  color: #fff;
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}

.thumbnail__text a, .thumbnail__link a {
  color: #9cfe01;
}

.thumbnail__text a:hover, .thumbnail__link a:hover {
  color: #05d604;
}

.thumbnail__text a:active, .thumbnail__link a:active {
  color: #fff;
}

@media screen and (width <= 767px) {
  .thumbnail__text, .thumbnail__link {
    font-size: 20px;
    line-height: 24px;
  }
}

.thumbnail__link:hover {
  color: #05d604;
}

.thumbnail__link:active {
  color: #fff;
}
/*# sourceMappingURL=index.f18de822.css.map */
